























































































































































































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import useMisc from "@/use/misc";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(_, { root }) {
    const { getPackageName, getLanguageVersion } = useMisc({ root });

    const state = reactive({
      currency: "",
      loading: false,
    });

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    return { getPackageName, getLanguageVersion, state };
  },
});
